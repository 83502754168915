import { UserManager, WebStorageStateStore } from "oidc-client-ts";

import { config } from "./config";

const { origin } = globalThis.location;

const userManager = new UserManager({
  authority: config.authUrl,
  client_id: config.authCustomerClientId,
  redirect_uri: `${origin}/login`,
  response_type: "code",
  scope: {
    "e-identitet": "openid",
    signicat: "openid nin",
  }[config.authIdentityProvider],
  accessTokenExpiringNotificationTimeInSeconds: 3,
  silent_redirect_uri: `${origin}/login/silent`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  post_logout_redirect_uri: `${origin}/logout/callback`,
  redirectMethod: "replace",
});

// TODO: Listen for "userUnloaded" or other relevant event and redirect to login page directly instead of logout to landing page.
// This has to be coordinated with the retry and/or logoutLink in apollo client setup that do the logout if 401 is received.

export { userManager };
