const getOptional = (suffix: string): string | undefined => {
  return process.env[`REACT_APP_${suffix}`];
};

const getRequired = (suffix: string): string => {
  const value = getOptional(suffix);

  if (value === undefined) {
    throw new ReferenceError(
      `Missing environment variable: REACT_APP_${suffix}`,
    );
  }

  return value;
};

const config = {
  apiUrl: getRequired("API_URL"),
  authCustomerClientId: getRequired("AUTH_CUSTOMER_CLIENT_ID"),
  authIdentityProvider: getRequired("AUTH_IDENTITY_PROVIDER"),
  authUrl: getRequired("AUTH_URL"),
  geography: getOptional("GEOGRAPHY"),
  patientPortalBaseUrl: "https://zymego.app",
  websiteUrl: getOptional("WEBSITE_URL"),
};

export { config };
