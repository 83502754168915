import { config } from "./config";
import { userManager } from "./user-manager";

const isLoginRoute = globalThis.location.pathname === "/login";
const isSilentLoginRoute = globalThis.location.pathname === "/login/silent";
const isLogoutRoute = globalThis.location.pathname === "/logout";
const isLogoutCallbackRoute =
  globalThis.location.pathname === "/logout/callback";

void (async function () {
  await userManager.clearStaleState();
  let user: Awaited<ReturnType<typeof userManager.getUser>> | void =
    await userManager.getUser();

  if (isLogoutRoute) {
    userManager.signoutRedirect();

    return;
  }

  if (isLogoutCallbackRoute) {
    userManager.signoutCallback();
    globalThis.location.replace(config.websiteUrl ?? "https://zymego.com");
    return;
  }

  if (isLoginRoute || isSilentLoginRoute) {
    try {
      user = await userManager.signinCallback();

      if (isSilentLoginRoute) {
        return;
      }
    } catch (error) {
      if (user?.state !== "retry") {
        void userManager.signinRedirect({ state: "retry" });
        return;
      }

      // TODO: Render nice error view
      console.error({ error });
      void userManager.clearStaleState();
      return;
    }
  }

  // Remove the user if the user is expired
  // so a proper sign-in flow can be started
  if (user?.expired) {
    await userManager.removeUser();
    user = undefined;
  }

  if (user) {
    void import("./init");
    const locationState = (
      user.state as
        | {
            locationState?: { pathname: string; historyState: unknown };
          }
        | undefined
    )?.locationState;

    globalThis.history.replaceState(undefined, "", "/");
    globalThis.history.replaceState(
      locationState?.historyState,
      "",
      locationState?.pathname ?? "/",
    );
  } else {
    const locationState = {
      pathname: globalThis.location.pathname + globalThis.location.search,
      historyState: globalThis.history.state,
    };

    void userManager.signinRedirect({
      state: { locationState },
    });
  }
})();
